// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-client-name-js": () => import("./../src/templates/client-name.js" /* webpackChunkName: "component---src-templates-client-name-js" */),
  "component---src-templates-offering-name-js": () => import("./../src/templates/offering-name.js" /* webpackChunkName: "component---src-templates-offering-name-js" */),
  "component---src-templates-partner-name-js": () => import("./../src/templates/partner-name.js" /* webpackChunkName: "component---src-templates-partner-name-js" */),
  "component---src-templates-project-name-js": () => import("./../src/templates/project-name.js" /* webpackChunkName: "component---src-templates-project-name-js" */),
  "component---src-templates-service-name-js": () => import("./../src/templates/service-name.js" /* webpackChunkName: "component---src-templates-service-name-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-team-name-js": () => import("./../src/templates/team-name.js" /* webpackChunkName: "component---src-templates-team-name-js" */)
}

